define("discourse/plugins/layouts-topic-list/discourse/widgets/topic-list", ["exports", "discourse/widgets/widget", "discourse-common/lib/get-owner", "discourse/lib/url", "discourse/lib/text", "virtual-dom", "discourse/widgets/raw-html"], function (_exports, _widget, _getOwner, _url, _text, _virtualDom, _rawHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var layoutsError;
  var layouts;

  try {
    layouts = requirejs('discourse/plugins/discourse-layouts/discourse/lib/layouts');
  } catch (error) {
    layouts = {
      createLayoutsWidget: _widget.createWidget
    };
    console.error(error);
  }

  var _default = layouts.createLayoutsWidget('topic-list', {
    defaultState: function defaultState(attrs) {
      var topicLists = this.siteSettings.layouts_topic_lists.split('|');
      return {
        gotTopics: false,
        topics: [],
        currentType: topicLists[0],
        topicLists: topicLists
      };
    },
    topicList: function topicList(topics, loginRequired) {
      var _this = this;

      if (loginRequired && !this.currentUser) {
        return this.attach('login-required');
      }

      if (!this.state.gotTopics) {
        return [(0, _virtualDom.h)('div.spinner.small')];
      } else if (!topics || topics.length < 1) {
        return [(0, _virtualDom.h)('li', I18n.t("filters.".concat(this.state.currentType, ".none")))];
      }

      return topics.map(function (t) {
        return _this.attach('layouts-topic-list-item', {
          topic: t
        });
      });
    },
    topics: function topics() {
      return this.topicList(this.state.topics, true);
    },
    getTopics: function getTopics() {
      var _this2 = this;

      var store = (0, _getOwner.getOwner)(this).lookup('store:main');
      store.findFiltered('topicList', {
        filter: this.state.currentType,
        params: {
          list_widget: true
        }
      }).then(function (result) {
        if (_this2.state) {
          _this2.state.topics = result.topics;
          _this2.state.gotTopics = true;

          _this2.scheduleRerender();
        }
      });
    },
    buildTitle: function buildTitle(type) {
      var currentType = this.state.currentType;
      var active = currentType === type;
      var classes = 'list-title';
      if (active) classes += ' active';
      return this.attach('link', {
        action: 'showList',
        actionParam: type,
        title: "filters.".concat(type, ".help"),
        label: "filters.".concat(type, ".title"),
        className: classes
      });
    },
    html: function html(attrs, state) {
      var _this3 = this;

      var contents = [];
      var currentUser = this.currentUser;

      if (state) {
        if (currentUser && !state.gotTopics) {
          this.getTopics();
        }

        var titleContents = [];

        if (state.topicLists) {
          state.topicLists.forEach(function (list) {
            titleContents.push([_this3.buildTitle(list)]);
          });
        }

        contents.push([(0, _virtualDom.h)('div.widget-multi-title', titleContents), (0, _virtualDom.h)('div.widget-list', (0, _virtualDom.h)('ul', this.topicList(state.topics, true)))]);
      }

      return [(0, _virtualDom.h)('div.widget-container.app', (0, _virtualDom.h)('div.widget-inner', contents))];
    },
    showList: function showList(currentType) {
      this.state.currentType = currentType;
      this.state.gotTopics = false;
      this.scheduleRerender();
    }
  });

  _exports.default = _default;
  (0, _widget.createWidget)('layouts-topic-list-item', {
    tagName: 'li',
    html: function html(attrs) {
      var title = attrs.topic.get('fancyTitle');
      return (0, _virtualDom.h)('div.title', new _rawHtml.default({
        html: "<span>".concat((0, _text.emojiUnescape)(title), "</span>")
      }));
    },
    click: function click() {
      var url = this.attrs.topic.get('url');

      _url.default.routeTo(url);
    }
  });
});

